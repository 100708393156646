<template>
  <div class="worksDetail">
    <div v-loading="loading"></div>
    <div class="crumb" v-if="clientWidth > 750">
      <breadcrumb :refer="refer"></breadcrumb>
    </div>
    <div v-if="IsData" class="worksDetail-content">
      <div class="Details-banner">
        <img v-if="worksDetail.banner" :src="worksDetail.banner" alt="" width="100%">
        <img v-else src="@/assets/PC/keenMind/keendetailbanner.png" alt="" width="100%">
      </div>
      <div class="Details-title"> {{ worksDetail.title }} </div>
      <div v-if="worksDetail.introduction" class="Details-introduction"> {{ worksDetail.introduction }} </div>
      <div v-if="worksDetail.video">
        <videos :videoUrl="worksDetail.video"></videos>
      </div>
      <div class="Details-body" v-html="worksDetail.content"></div>
      <!-- <div class="Details-operate tc">
        <p style="margin-bottom: 5px">觉得作品不错就投个票吧！</p>
        <el-button v-if="!worksDetail.isVote" type="info" size="mini" @click="vote">投票</el-button>
        <el-button v-else type="warning" size="mini" disabled>已投票</el-button>
      </div> -->
    </div>
    <div v-if="voteSuccess" class="shade flex0">
      <div class="shade-content">
        <img :src="alertContent.bgImg" alt="" width="100%">
        <div class="shade-body flex0">
          <div class="shade-text tc" v-html="alertContent.text"></div>
          <div class="shade-btn">
            <el-button type="info" @click="operation(alertContent.type)">{{ alertContent.buttonText }}</el-button>
          </div>
        </div>
        <i v-if="alertContent.buttonText != '抽奖'" class="el-icon-circle-close co-fff cur shade-body-close"
          @click="operation('cancel')"></i>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import { GetActityShowInfo, VoteActityShow, GetPrize } from "@/api/KeenMind.js";
import { debounce, GetWxShares } from '@/utils/common.js';
import videos from "@/components/Xgvideos/Index.vue"

export default {
  name: "worksDetail",
  data() {
    return {
      loading: true,
      refer: [
        { name: "首页", href: "/" },
        { name: "剑鸣", href: "/Home/keenMind" },
      ],
      IsData: false,
      worksDetail: {},
      voteSuccess: false,
      alertContent: {},
    }
  },
  mounted() {
    GetActityShowInfo({ actityShowId: this.$route.query.id }).then(res => {
      if (res.status === 1) {
        this.refer.push({ name: "作品展示", href: "/Home/ActivityPreview?id=" + res.data.activityId }, { name: res.data.title, href: "" })
        this.worksDetail = res.data;
        this.IsData = true;
        const share = {
          title:  res.data.title,
          desc: ''
        }
        GetWxShares(share);
      } else {
        this.$toast(res.message);
        this.$router.push('/Home/keenMind');
      }
      this.loading = false;
    })
  },
  methods: {
    // !投票
    vote() {
      if (!this.$store.state.token) {
        this.$toast("请登录后操作");
        return false;
      }
      debounce(() => {
        VoteActityShow({ actityShowId: this.worksDetail.id }).then(res => {
          if (res.status === 1) {
            this.worksDetail.isVote = true
            this.alertContent = {
              bgImg: require("@/assets/PC/keenMind/vote.png"),
              text: "感谢您的投票，<br>您获得了一次抽奖机会，<br>点击下方按钮即可参与抽奖！",
              buttonText: "抽奖",
              type: 'vote',
            }
            this.voteSuccess = true;
          } else {
            this.$toast(res.message)
          }
        }).catch(err => {
          this.$toast(err)
        })
      }, 1000)
    },
    // !抽奖
    operation(type) {
      if (type == "vote") {
        const data = {
          actityShowId: this.worksDetail.id,
          actityId: this.worksDetail.activityId
        }
        GetPrize(data).then(res => {
          if (res.status == 1) {
            if (res.data.isPrize) {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/win.png"),
                text: "恭喜您获得" + res.data.prizeName,
                buttonText: "返回",
                type: 'cancel'
              }
            } else {
              this.alertContent = {
                bgImg: require("@/assets/PC/keenMind/nowin.png"),
                text: "<img src='" + require('@/assets/PC/keenMind/nowin-icon.png') + "' width='35%' style='margin-bottom: 10px'><br>很遗憾，您未中奖！",
                buttonText: "返回",
                type: 'cancel'
              }
            }
          }
        })
      } else if (type == "cancel") {
        this.voteSuccess = false
      }
    },
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
  },
  components: {
    breadcrumb,
    videos
  },
}
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.worksDetail {
  .el-button--warning.is-disabled {
    background: #909399;
    border-color: #909399;
  }

  max-width: 1200px;
  margin: 0 auto;

  .Details-banner {
    margin-bottom: 15px;
  }

  .Details-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .Details-introduction {
    font-size: 16px;
    line-height: 24px;
    color: #666;
    margin-bottom: 30px;
  }

  .Details-file {
    margin-bottom: 10px;
  }

  .Details-body {
    padding-bottom: 20px;
  }

  .Details-body img {
    max-width: 100%;
    border-radius: 10px;
    margin: 30px 0;
  }

  .Details-body table {
    max-width: 100%;
    border-left: 1px #ccc solid;
    border-top: 1px #ccc solid;
  }

  .Details-body tr th,
  .Details-body tr td {
    border-right: 1px #ccc solid !important;
    border-bottom: 1px #ccc solid !important;
  }

  .Details-operate {
    padding-bottom: 40px;
  }

  .shade-content {
    width: 500px;
  }

  .shade-body {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
  }

  .shade-text {
    line-height: 2;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .shade-body-close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

@media (max-width: 1200px) {
  .worksDetail {
    width: 100%;
    padding: 0 30px;
  }
}

@media (max-width: 750px) {
  .worksDetail {
    width: 100%;
    padding: vw(30);

    .Details-body img {
      border-radius: vw(20);
      margin: vw(30) 0;
    }

    .shade-content {
      width: 90%
    }

    .shade-text {
      line-height: 2;
      font-size: vw(32);
      margin-bottom: vw(60);
      padding-top: vw(30);
    }
  }
}
</style>